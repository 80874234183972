import { useQuery } from '@tanstack/vue-query';
import {
  type FetchPromotionsOptions,
  fetchPromotions,
} from '~/features/promotions/members';

export function usePromotionsQuery(options: FetchPromotionsOptions) {
  return useQuery({
    queryKey: ['promotions', options],
    queryFn: async () => {
      try {
        const data = await fetchPromotions(options);
        if (!data) {
          return [];
        }

        return data ?? [];
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60, // 1 hour,
  });
}
