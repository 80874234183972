import type { Promotion } from '@tn/shared';
import type { FetchPromotionsOptions } from '~/features/promotions/members';

export const fetchPromotions = async ({
  brandId,
  limit = 25,
}: FetchPromotionsOptions) => {
  const { headers } = authenticatedRequestHeaders();

  return await $fetch<Promotion[]>('/api/promotions', {
    method: 'GET',
    headers,
    query: {
      ...(brandId ? { brandId } : {}),
      ...(limit ? { limit } : {}),
    },
  });
};
