import { usePromotionsQuery } from '../queries';
import { formatDistanceStrict } from 'date-fns';
import type { FetchPromotionsOptions } from '~/features/promotions/members';
import { useCyclePeriods, useCyclesData } from '~/features/cycles';
import type { RemoteConfig } from '@tn/shared';

export function usePromotions(options: FetchPromotionsOptions = {}) {
  // Will return all active promotions if params are not specified
  const { data: promotions, isLoading, suspense } = usePromotionsQuery(options);

  const { currentCycle, isLoading: isCyclesLoading } = useCyclesData();
  const now = useNow();

  const { isPromotionPeriodActive } = useCyclePeriods();

  const activePromotions = computed(() => promotions.value || []);

  const brandActivePromotions = computed(() =>
    activePromotions.value.slice(0, 3)
  );

  const remoteConfig = useState<RemoteConfig>('remote-config');
  const isPromotionsPeriodFlagEnabled = computed(
    () => remoteConfig.value.isPromotionsPeriodEnabled
  );

  const defaultPromotion = computed(() => {
    if (activePromotions.value.length === 0) return null;

    const defaultPromo = activePromotions.value.find((item) => item?.isDefault);
    const firstPromotion = activePromotions.value[0];

    if (
      isPromotionsPeriodFlagEnabled.value &&
      (!currentCycle?.value?.orderPlacementDeadlineDate ||
        now.value < currentCycle.value?.orderPlacementDeadlineDate)
    ) {
      return null;
    }

    return defaultPromo || firstPromotion;
  });

  const promotionsReferenceDate = computed(() => {
    if (!isPromotionsPeriodFlagEnabled.value) return null;

    if (!currentCycle.value) return null;

    return isPromotionPeriodActive.value
      ? new Date(currentCycle.value.endDate)
      : new Date(currentCycle.value.orderPlacementDeadlineDate);
  });

  const remainingTime = computed(() => {
    if (!isPromotionsPeriodFlagEnabled.value) {
      return null;
    }

    return promotionsReferenceDate.value
      ? formatDistanceStrict(promotionsReferenceDate.value, now.value)
      : null;
  });

  const loading = computed(() => isLoading.value || isCyclesLoading.value);

  return {
    activePromotions,
    defaultPromotion,
    brandActivePromotions,
    loading,
    isPromotionPeriodActive: computed(() =>
      isPromotionsPeriodFlagEnabled.value ? isPromotionPeriodActive.value : true
    ),
    remainingTime,
    isPromotionsPeriodFlagEnabled,
    suspense,
  };
}
